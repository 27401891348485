import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'next-i18next';

import Button from '@components/atoms/button';
import CategoryMenu from '@components/atoms/categoryMenu';
import NextLink from '@components/atoms/link';
import Logo from '@components/atoms/logo';
import MyCourseMenu from '@components/molecules/myCourseMenu';
import NotificationBell from '@components/molecules/notification/notificationBell';
import SearchBar from '@components/molecules/search/searchBar';
import UniversityMasterDegreeMenuPopover from '@components/molecules/universityMasterDegreeMenuPopover';
import HeaderStyle from '@components/organisms/header/style';
import { login, register } from '@helpers/auth';
import { GENERAL_MENU, UserType } from '@helpers/constants';
import { useWindowSize, useBooleanReduxState, useProfile } from '@helpers/use';
import { useNotification } from '@hooks/useNotification';
import theme from 'src/theme';

const UserMenuDrawer = dynamic(() => import('@components/molecules/userMenuDrawer'));
const NotificationDialog = dynamic(() => import('@components/molecules/notification/notificationDialog'));
const UserMenuPopover = dynamic(() => import('@components/molecules/userMenuPopover'));
const SearchDrawer = dynamic(() => import('@components/molecules/search/searchDrawer'));

type HeaderProps = {
  isHideSearch: boolean;
};
const DesktopHeader = ({ isHideSearch }: HeaderProps) => {
  const { profile, isLoading } = useProfile();
  const { t } = useTranslation();

  const menuAction = profile ? (
    <>
      {[UserType.STUDENT, UserType.INSTRUCTOR, UserType.SYSTEM_ADMIN].includes(profile.role as UserType) && (
        <>
          <Box marginRight={2}>
            <MyCourseMenu />
          </Box>
          <Box marginRight={2}>
            <NotificationBell />
          </Box>
        </>
      )}
      <UserMenuPopover />
    </>
  ) : (
    <>
      <Box width={100} mr={1}>
        <Button data-testid="loginButton" onClick={() => login()} variant="outlined" color="primary" fullWidth>
          {t('login')}
        </Button>
      </Box>
      <Box width={100}>
        <Button
          data-testid="registerButton"
          onClick={register}
          className="register-button"
          variant="contained"
          color="primary"
          fullWidth
        >
          {t('register')}
        </Button>
      </Box>
    </>
  );

  return (
    <AppBar position="static" className="desktop-appbar">
      <Toolbar>
        <Logo />
        <CategoryMenu />
        {isHideSearch ? <Box sx={{ flex: '1 0.99 auto' }} /> : <SearchBar />}
        {GENERAL_MENU.map((menu) => {
          if (menu.name === 'master_degree') {
            return <UniversityMasterDegreeMenuPopover />;
          }
          return (
            <NextLink href={menu.link} key={menu.name}>
              <Button key={menu.name} style={{ textTransform: 'none' }}>
                {t(menu.tName)}
              </Button>
            </NextLink>
          );
        })}
        <Divider className="divider" orientation="vertical" />
        <Box display="flex" alignItems="center">
          {isLoading ? (
            <Box width={100}>
              <Skeleton animation="wave" height={10} />
              <Skeleton animation="wave" height={10} />
              <Skeleton animation="wave" height={10} />
            </Box>
          ) : (
            menuAction
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const MobileHeader = ({ isHideSearch }: HeaderProps) => {
  const { width } = useWindowSize();
  const { profile, isLoading } = useProfile();
  const [isUserMenuDrawerOpen, setIsUserMenuDrawerOpen] = useBooleanReduxState(
    'isUserMenuDrawerOpen',
    'set_user_menu_drawer_open',
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { isNotificationSeen, seeNotification } = useNotification();

  useEffect(() => {
    if (isUserMenuDrawerOpen) setIsUserMenuDrawerOpen(false);
  }, [width]);

  const closeDrawer = () => {
    setIsUserMenuDrawerOpen(false);
  };

  const openDrawer = () => {
    setIsUserMenuDrawerOpen(true);
  };

  const openSearch = () => setIsSearchOpen(true);
  const closeSearch = () => setIsSearchOpen(false);

  return (
    <AppBar position="static" className="mobile-appbar">
      <NotificationDialog />
      <Grid container direction="row" alignItems="center">
        <Grid item xs={2} className="menu-icon-container">
          <IconButton color="primary" onClick={openDrawer}>
            <MenuIcon />
            {!isNotificationSeen && <div className="alert-dot" />}
          </IconButton>
          <UserMenuDrawer
            profile={profile}
            isLoading={isLoading}
            isOpen={isUserMenuDrawerOpen}
            onClose={closeDrawer}
            isNotificationSeen={isNotificationSeen}
            seeNotification={seeNotification}
          />
        </Grid>
        <Grid item xs={8} container justifyContent="center">
          <Logo />
        </Grid>
        {!isHideSearch && (
          <Grid item xs={2} className="menu-icon-container-right">
            <IconButton onClick={openSearch}>
              <FontAwesomeIcon style={{ fontSize: '23px' }} color={theme.palette.primary.main} icon={faSearch} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <SearchDrawer isOpen={isSearchOpen} onClose={closeSearch} />
    </AppBar>
  );
};

const Header = ({ hideHeaderOnScroll = false }) => {
  const router = useRouter();
  const isHideSearch = router.pathname.includes('/cpd') && !router.pathname.includes('/cpd/courses');
  return (
    <HeaderStyle hideHeaderOnScroll={hideHeaderOnScroll}>
      <DesktopHeader isHideSearch={isHideSearch} />
      <MobileHeader isHideSearch={isHideSearch} />
    </HeaderStyle>
  );
};

export default Header;
