import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'next-i18next';

import NotificationBellStyle, { NotificationPopup } from './style';

import { useNotification } from '@hooks/useNotification';
import Button from 'src/components/atoms/button';
import NotificationList from 'src/components/molecules/notification/notificationList';

const NotificationBell = () => {
  const { isNotificationSeen, seeNotification } = useNotification();
  const popupState = usePopupState({ variant: 'popover', popupId: 'notificationPopup' });
  const { t } = useTranslation('common');

  // @ts-expect-error: bindHover return wrong type
  const { onMouseOver, ...triggerProps } = bindHover(popupState);

  const onHover = (event: any) => {
    onMouseOver(event);
    seeNotification();
  };

  return (
    <NotificationBellStyle>
      <Button onMouseOver={onHover} {...triggerProps}>
        <FontAwesomeIcon icon={faBell} />
        {!isNotificationSeen && <div className="alert-dot" />}
      </Button>
      <NotificationPopup
        {...bindMenu(popupState)}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="notificationPopup"
      >
        <Box pb={1} px={2}>
          <Typography variant="h4">{t('my_notification')}</Typography>
        </Box>
        <NotificationList />
      </NotificationPopup>
    </NotificationBellStyle>
  );
};

export default NotificationBell;
